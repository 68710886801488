<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../helper/Gen';
    Vue.component("Header",()=>import("@frontend/part/Header2.vue"))

    export default {
        extends: BaseVue,
        data() {
            return {
            	row:{},
            	popular:[],
            	tags:[],
            	page404:false,
            }
        },
        mounted() {
            this.$set(this.$root, 'page', this);
            Vue.component("Header",()=>import("@frontend/part/Header2.vue"))
            if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
            setTimeout(() => {
                SEMICOLON.documentOnReady.init()
            }, 100)
            $(document).ready(() => {
                AOS.init()
            })
            this.apiGetCustomerDetail()
        },
        methods: {
        	apiGetCustomerDetail(){
        		Gen.apirest('/article-detail/'+this.$route.params.slug, {}, (err, resp)=>{
	              if(err) console.log(err)
	              if(resp.code==404)return this.page404=true
	              this.row=resp.data
	              this.popular=resp.popular
	              this.tags=resp.tags
	              this.$root.topProgress.done()
	          })
        	},        	
		    fbShare(){
		      var link = location.href;
		       window.open("https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(link), 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
		    },
		    twShare(){
		      var link = location.href;
		      var title = this.row.ap_title;
		       window.open("https://twitter.com/intent/tweet?text="+encodeURIComponent(title)+"&url="+encodeURIComponent(link), 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
		    },
		     lkShare(){
		      var link = location.href;
		      var title = this.row.ap_title;
		       window.open("https://www.linkedin.com/shareArticle?mini=true&url="+encodeURI(link)+"&title="+encodeURI(title),"","modal=yes")
		    },
        },
        watch:{
        	"$route.params.slug"(v){
        		this.apiGetCustomerDetail()
		    	},
        }
    };
</script>
<template>
	<div Content>
		<Component404 v-if="page404"></Component404>
		<div v-else>
			<Header></Header>
			<section id="page-title" class="page-title-mini">
				<div class="container clearfix">
					<h1 class="d-none d-md-block">&nbsp;</h1>
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><router-link :to="{name:'index'}">{{ web.mn_home }}</router-link></li>
						<li class="breadcrumb-item"><router-link :to="{name:'Artikel'}">{{ web.mn_artikel }}</router-link></li>
						<li class="breadcrumb-item active" aria-current="page">{{ row.ap_title }}</li>
					</ol>
				</div>
			</section>
			<section id="content" class="overflow-none">
				<div class="content-wrap">
					<section class="section nobottommargin  notopmargin">
						<div class="container">
							<div class="row justify-content-center">
								<div class="col-lg-8">
									<div class="detail_article">
										<h2>{{row.ap_title}}</h2>
										<div class="wrap_author">
											<ul>
												<li><a href="javascript:;"><i class="icon-user21"></i> {{row.ap_author_name}}</a></li>
												<li><a href="javascript:;"><i class="icon-calendar21"></i> {{(row.ap_publish_date).dates('format')}}</a></li>
											</ul>
										</div>
										<div class="form-row mt-2">
											<div class="col-4 col-md-2">
												<div class="wrap_author mb-2">
													<ul>
														<li><a href="javascript:;">Share Article</a></li>
													</ul>
												</div>
											</div>
											<div class="col-6 pl-0 pl-mb-3 col-lg-6 mb-3">
												<div class="d-flex">
													<a href="#" @click="fbShare()"
														class="social-icon si-rounded si-colored si-small si-facebook"
														title="Facebook">
														<i class="icon-facebook"></i>
														<i class="icon-facebook"></i>
													</a>
													<a href="#" @click="twShare()"
														class="social-icon si-small  si-colored si-rounded si-twitter"
														title="Twitter">
														<i class="icon-twitter"></i>
														<i class="icon-twitter"></i>
													</a>
													<a href="#" @click="lkShare()"
														class="social-icon si-small si-colored si-rounded si-linkedin"
														title="LinkedIn">
														<i class="icon-linkedin"></i>
														<i class="icon-linkedin"></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div class="article_content_detail">
										<div class="list_img">
											<VImg width="100%" class="img-responsive" :src="uploader(row.ap_photo)" :alt="row.ap_title" :title="row.ap_title"></VImg>
										</div>
										<div v-html="row.ap_desc"></div>
									</div>
								</div>
								<div class="col-lg-4 side-art">
									<div class="wrap_side_article" style="width:100%;">
										<h3>{{ web.lbl_artikel_terpopuler }}</h3>
										<div v-for="(p,k) in popular" class="wrap_article_side">
											<div class="row">
												<div class="col-md-4 col-5 pr-md-0 pr-lg-3">
													<div class="pict_blog_side">
														<VImg class="img-responsive" :src="uploader(p.ap_photo, '137x90')" :alt="p.ap_title" :title="p.ap_title"></VImg>
													</div>
												</div>
												<div class="col-md-8 col-7">
													<div class="wrap_article_side_content">
														<h3>
															<router-link :to="{name:'ArticleDetail',params:{slug:p.ap_slug}}">
																{{ p.ap_title }}
															</router-link>
														</h3>
														<div class="wrap_author">
															<ul>
																<li><a href=""><i class="icon-calendar21"></i>&nbsp;{{(p.ap_publish_date).dates('format')}}
																	</a></li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="wrap_side_article" style="width:100%;">
										<h3>{{ web.lbl_tags }}</h3>
										<div class="tagcloud">
											<router-link v-for="(v2,k2) in tags" :to="{name:'DetailTags',params:{slug:v2.tag_slug}}">
												{{ v2.tag_name }}
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</section>
		</div>
	</div>
</template>